import React from 'react'
import Logo from '../assets/icons/logo.svg'
import LogoDark from '../assets/icons/logo-dark.svg'
import Vk from '../assets/icons/vk.svg'
import Instagram from '../assets/icons/instagram.svg'
import Facebook from '../assets/icons/facebook.svg'
import Youtube from '../assets/icons/youtube.svg'
import Ok from '../assets/icons/ok.svg'
import VkWhite from '../assets/icons/vk-white.svg'
import InstagramWhite from '../assets/icons/instagram-white.svg'
import FacebookWhite from '../assets/icons/facebook-white.svg'
import YoutubeWhite from '../assets/icons/youtube-white.svg'
import OkWhite from '../assets/icons/ok-white.svg'
import Call from '../assets/icons/call.svg'
import Location from '../assets/icons/location.svg'
import Close from '../assets/icons/close.svg'
import Link from '../assets/icons/link.svg'
import Download from '../assets/icons/download.svg'
import Purse from '../assets/icons/purse.svg'
import Arrows from '../assets/icons/arrows.svg'
import Clock from '../assets/icons/clock.svg'
import Card from '../assets/icons/card.svg'
import Ws from '../assets/icons/ws.svg'
import Play from '../assets/icons/play.svg'
import Arrow from '../assets/icons/arrow.svg'
import Communication from '../assets/icons/communication.svg'
import Confidentiality from '../assets/icons/confidentiality.svg'
import Monitoring from '../assets/icons/monitoring.svg'
import Search from '../assets/icons/search.svg'
import Location2 from '../assets/icons/location2.svg'
import Review from '../assets/icons/review.svg'
import StarGold from '../assets/icons/star-gold.svg'
import Star from '../assets/icons/star.svg'
import ArrowBack from '../assets/icons/arrow-back.svg'
import Again from '../assets/icons/again.svg'
import Contact from '../assets/icons/contact.svg'
import Guarantee from '../assets/icons/quarantee.svg'
import Edit from '../assets/icons/edit.svg'
import Analysis from '../assets/icons/analysis.svg'
import Application from '../assets/icons/application.svg'
import Calculations from '../assets/icons/calculations.svg'
import Car from '../assets/icons/card.svg'
import Consultation from '../assets/icons/consultation.svg'
import Deal from '../assets/icons/deal.svg'
import Documents from '../assets/icons/documents.svg'
import Money from '../assets/icons/money.svg'
import Promptness from '../assets/icons/promptness.svg'
import Purchases from '../assets/icons/purchases.svg'
import Selection from '../assets/icons/selection.svg'
import Settings from '../assets/icons/settings.svg'
import Flag from '../assets/icons/flag.svg'
import Chat from '../assets/icons/chat.svg'
import Chats from '../assets/icons/chats.svg'
import Minus from '../assets/icons/minus.svg'
import Plus from '../assets/icons/plus.svg'
import Exit from '../assets/icons/exit.svg'
import Add from '../assets/icons/add.svg'
import Doc from '../assets/icons/doc.svg'
import DocCheck from '../assets/icons/doc-check.svg'
import User from '../assets/icons/user.svg'
import LogoSmall from '../assets/icons/logo-small.svg'
import Attach from '../assets/icons/attach.svg'
import Back from '../assets/icons/back.svg'
import EditSmall from '../assets/icons/edit-small.svg'
import UserWhite from '../assets/icons/user-white.svg'
import UserFilled from '../assets/icons/user-filled.svg'
import YandexDzen from '../assets/icons/yandex-dzen.svg'
import TikTok from '../assets/icons/tik-tok.svg'
import Viber from '../assets/icons/viber.svg'
import ArrowTop from '../assets/icons/arrow-top.svg'

const icons = {
    'yandex_zen': YandexDzen,
    'tik_tok': TikTok,
    'user-filled': UserFilled,
    'user-white': UserWhite,
    'edit-small': EditSmall,
    back: Back,
    attach: Attach,
    'logo-small': LogoSmall,
    'logo-dark': LogoDark,
    user: User,
    doc: Doc,
    'doc-check': DocCheck,
    add: Add,
    minus: Minus,
    plus: Plus,
    chat: Chat,
    chats: Chats,
    flag: Flag,
    settings: Settings,
    selection: Selection,
    purchases: Purchases,
    promptness: Promptness,
    money: Money,
    documents: Documents,
    deal: Deal,
    consultation: Consultation,
    car: Car,
    calculations: Calculations,
    application: Application,
    analysis: Analysis,
    edit: Edit,
    guarantee: Guarantee,
    contact: Contact,
    again: Again,
    'arrow-back': ArrowBack,
    'star-gold': StarGold,
    star: Star,
    search: Search,
    location2: Location2,
    review: Review,
    communication: Communication,
    confidentiality: Confidentiality,
    monitoring: Monitoring,
    logo: Logo,
    vk: Vk,
    instagram: Instagram,
    fb: Facebook,
    youtube: Youtube,
    ok: Ok,
    'vk-white': VkWhite,
    'instagram-white': InstagramWhite,
    'fb-white': FacebookWhite,
    'youtube-white': YoutubeWhite,
    'ok-white': OkWhite,
    location: Location,
    call: Call,
    close: Close,
    link: Link,
    download: Download,
    purse: Purse,
    arrows: Arrows,
    clock: Clock,
    card: Card,
    ws: Ws,
    play: Play,
    arrow: Arrow,
    exit: Exit,
    viber: Viber,
    'arrow-top': ArrowTop,
}

export default function ({ name, width, height }) {
    return icons[name] ? React.createElement(icons[name], { width, height }) : null
}
